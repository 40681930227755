.multiple-checkbox-container {
    display: flex;
    flex-direction: column;
}

.multiple-checkbox__list {
    margin-inline-start: 1rem;
}

.my-checkbox-label {
    display: flex;
    gap: 1rem;

    align-items: center;

    text-align: justify;

    cursor: pointer;
}

.my-checkbox-custom {
    width: 20px;
    height: 20px;

    background-color: transparent;
    border: 2px solid rgba(0, 0, 0, 0.5);
    border-radius: .2rem;
}

.my-checkbox__text {
    width: 98%;
}

.my-checkbox:checked+.my-checkbox-custom {
    background-image: url(../../images/check.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    border: 2px solid #60a5fa;

    background-color: #60a5fa;
}

.my-checkbox-label:hover .my-checkbox-custom {
    border-color: #60a5fa;
}

.my-checkbox:focus-visible+.my-checkbox-custom {
    border-color: #60a5fa;
}