.navbar {
    width: 100%;

    margin-bottom: 1rem;

    background-color: #fff;
}

.navbar__logo {
    width: 6rem;
}

@media(min-width: 640px) {
    .navbar__logo {
        width: 8rem;
    }
}