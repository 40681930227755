.my-popup {
    position: fixed;
    top: 0;
    left: 0;

    display: flex;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, .3);
}

.my-dialog {
    display: flex;
    flex-direction: column;

    gap: 1rem;

    margin: auto;
    padding: 1rem 2rem;

    background-color: #fff;
    border-radius: 1rem;
}

._big-dialog {
    width: 100%;
}

._small-dialog {
    width: auto;
}