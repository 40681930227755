.my-select-label {
    display: flex;
    flex-direction: column;

    width: 100%;

    margin-bottom: 0.5rem;
}

.my-select {
    width: 100%;

    border-bottom: 2px solid #60a5fa;

    background-color: transparent;   
    
    outline: none;
}

.my-select__option:first-child {
    display: none;
}

@media(min-width: 1024px) {
    .my-select-label {
        flex-direction: row;
    }

    .my-select {
        margin-left: 0.5rem;
    }
}