.timer {
    position: fixed;
    top: 5%;
    right: 5%;

    padding: .5rem;

    border: 2px solid #60a5fa;
    border-radius: 10%;

    background-color: #fff;

    font-weight: 700;
}