.send-button-container {
    display: flex;
    justify-content: center;

    padding-block-end: 1rem;
}

.send-button__text {
    font-weight: bold;
}

.send-button-popup {
    display: flex;
    justify-content: space-evenly;
}