
.profInput {
    padding: 5px;
    outline: none;
}

.profSelect {
    max-width: 100%;

    outline: none;
}

.mainColorBg {
    background-color: #60A5FA;
}

.mainColorBorder {
    border: 2px solid #60A5FA;
}

.formParagraph {
    padding: 5px;

    font-weight: bold;
}

.btn {
    padding: 0.8rem;

    color: #60A5FA;

    background-color: #fff;

    border: 2px solid #60A5FA;
    border-radius: 0.6rem;
}

.btn:hover {
    background-color: #60A5FA;

    color: #fff;
}

.btn:active {
    transform: scale(0.95);
}

.readyBtn {
    padding: 0.8rem;

    color: #fff;

    background-color: #60A5FA;

    border: 2px solid #fff;
    border-radius: 0.6rem;
}

.readyBtn:hover {
    background-color: #fff;

    color: #60A5FA;
}

.readyBtn:active {
    transform: scale(0.95);
}

.bigText {
    font-size: 24px;
}
