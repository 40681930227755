@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

.background {
  background-image: url('../../public/bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  max-height: 100%;
}

._container {
  width: 98%;

  margin: auto;
}

._mainText {
  font-size: 12px;
}

._semiText {
  font-size: 10px;
}

@media(min-width: 480px) {
  ._container {
    width: 80%;
  }

  ._mainText {
    font-size: 14px;
  }
}

@media(min-width: 640px) {
  ._container {
    width: 70%;
  }

  ._mainText {
    font-size: 16px;
  }
}

@media(min-width: 1024px) {
  ._container {
    width: 70%;
  }

  ._mainText {
    font-size: 18px;
  }
}

@media(min-width: 1366px) {
  ._container {
    width: 60%;
  }

  ._mainText {
    font-size: 20px;
  }
}

@media(min-width: 1920px) {
  ._container {
    width: 50%;
  }

  ._mainText {
    font-size: 22px;
  }
}

._question-text {
  width: auto;

  text-align: start;

  font-weight: 700;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);

  position: absolute;

  width: 1px;
  height: 1px;

  overflow: hidden;

  white-space: nowrap;
}

._no-wrap {
  white-space: nowrap;
}

.mainText {
  font-family: Arial;
}

.bigText {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.largeText {
  font-size: 1.5rem;
  line-height: 2rem;
}

.hugeText {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.position-absolute {
  position: absolute;
}

.position-top-0 {
  top: 0rem;
}

.position-top-5 {
  top: 1.25rem;
}

.position-top-14 {
  top: 3.5rem;
}

.position-left-0 {
  left: 0rem;
}

.position-right-0 {
  right: 0rem;
}

.position-bottom-0 {
  bottom: 0rem;
}

.position-bottom-5 {
  bottom: 1.25rem;
}

.position-relative {
  position: relative;
}

.z-index-10 {
  z-index: 10;
}

.z-index-20 {
  z-index: 20;
}

.display-flex {
  display: flex;
}

.display-block {
  display: block;
}

.display-flex-wrap {
  flex-wrap: wrap;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}

.text-justify-full {
  text-align: justify;
}

.text-justify-center {
  text-align: center;
}

.align-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.height-full {
  height: 100%;
}

.height-auto {
  height: auto;
}

.height-min {
  height: 0.75rem;
}

.height-3-rem {
  height: 3rem;
}

.height-4-rem {
  height: 4rem;
}

.height-13-rem {
  height: 13rem;
}

.height-vh {
  height: 100vh;
}

.width-auto {
  width: auto;
}

.width-full {
  width: 100%;
}

.width-two-thirds {
  width: 66.666667%;
}

.width-five-sixths {
  width: 83.333333%;
}

.width-half {
  width: 50%;
}

.width-three-fourths {
  width: 75%;
}

.width-three-fifths {
  width: 60%;
}

.width-one-fifths {
  width: 20%;
}

.width-seven-twelfth {
  width: 58.333333%;
}

.width-one-third {
  width: 33.333333%;
}

.width-quarter {
  width: 25%;
}

.width-one-sixth {
  width: 16.666667%;
}

.width-one-twelfth {
  width: 8.333333%;
}

.width-24-rem {
  width: 24rem;
}

.width-8-rem {
  width: 8rem;
}

.width-7-rem {
  width: 7rem;
}

.width-6-rem {
  width: 6rem;
}

.width-min {
  width: 0.75rem;
}

.margin-inline-auto {
  margin-left: auto;
  margin-right: auto;
}

.margin-top {
  margin-top: 0.5rem;
}

.margin-top-large {
  margin-top: 1.5rem;
}

.margin-top-huge {
  margin-top: 2.5rem;
}

.margin-bottom {
  margin-bottom: 0.5rem;
}

.margin-bottom-large {
  margin-bottom: 1.5rem;
}

.margin-bottom-huge {
  margin-bottom: 2.5rem;
}

.margin-right {
  margin-right: 0.5rem;
}

.margin-right-large {
  margin-right: 1.5rem;
}

.margin-left-min {
  margin-left: 0.25rem;
}

.margin-left-large {
  margin-left: 1.5rem;
}

.padding-all {
  padding: 0.5rem;
}

.padding-all-min {
  padding: 0.25rem;
}

.padding-all-large {
  padding: 1rem;
}

.padding-top {
  padding-top: 1.25rem;
}

.padding-left {
  padding-left: 0.5rem;
}

.background-color-white {
  background-color: #fff;
}

.background-color-transparent {
  background-color: transparent;
}

.border-main {
  border: 2px solid #60a5fa;
}

.border-bottom {
  border-bottom: 2px solid #60a5fa;
}

.border-bottom-radius {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.border-radius {
  border-radius: 0.75rem;
}

.border-radius-min {
  border-radius: 0.25rem;
}

.border-radius-full {
  border-radius: 1rem;
}

.shadow {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.text-color-blue {
  color: #60a5fa;
}

.text-underline {
  text-decoration-line: underline;
}

.font-weight-bold {
  font-weight: bold;
}

.text-indent {
  text-indent: 3rem;
}

.text-indent-large {
  text-indent: 5rem;
}

.nowrap {
  white-space: nowrap;
}

.break-word {
  overflow-wrap: break-word;
}

.input-outline-none {
  outline: none;
}

.input-resize-none {
  resize: none;
}

.input-overflow-hidden {
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

.inputStyle {
  background-color: transparent;
  outline: none;

  font-weight: normal;
}

.input-padding {
  padding: 0.25rem;
}

.buttonStyle {
  padding: 0.5rem;
  border: solid 2px #60a5fa;
  border-radius: 0.5rem;

  background-color: #fff;

  color: #60a5fa;
}

.buttonStyle:hover {
  color: #fff;

  background-color: #60a5fa;
}

.buttonStyle:active {
  transform: scale(0.95);
}

.arrowButton {
  padding: 0.5rem;
  border: solid 2px #60a5fa;
  border-radius: 100%;

  background-color: #fff;

  color: #60a5fa;
}

.arrowButton:hover {
  color: #fff;

  background-color: #60a5fa;
}

.arrowButton:active {
  transform: scale(0.90);
}

.iconButton:hover {
  color: #60a5fa;
}

.iconButton:active {
  transform: scale(0.8);
}

.active {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: 0.4s ease;
}

.inactive {
  opacity: 0;
  visibility: hidden;
  transform: scale(0.8);
  transition: 0.5s ease;
}

.table-border-collapse {
  border-collapse: collapse;
}

@media (min-width: 376px) and (max-width: 575px) {

  .mainText {
    font-size: 12px;
  }

  .navbarHeight {
    height: 2rem;
  }

  .personFlex {
    flex-direction: column;
  }

}

@media (min-width: 576px) and (max-width: 767px) {

  .mainText {
    font-size: 12px;
  }

  .navbarHeight {
    height: 2.5rem;
  }

  .personFlex {
    flex-direction: column;
  }

}

@media (min-width: 768px) and (max-width: 991px) {

  .mainText {
    font-size: 14px;
  }

  .navbarHeight {
    height: 2.5rem;
  }

  .personFlex {
    flex-direction: row;
  }

}

@media (min-width: 992px) and (max-width: 1199px) {

  .mainText {
    font-size: 16px;
  }

  .navbarHeight {
    height: 3rem;
  }

  .personFlex {
    flex-direction: row;
  }

}

@media (min-width: 1200px) and (max-width: 1499px) {

  .mainText {
    font-size: 18px;
  }

  .navbarHeight {
    height: 3rem;
  }

  .personFlex {
    flex-direction: row;
  }

}

@media (min-width: 1500px) and (max-width: 1919px) {
  .personFlex {
    flex-direction: row;
  }
}

@media (min-width: 1920px) and (max-width: 2099px) {

  .mainText {
    font-size: 20px;
  }

  .navbarHeight {
    height: 3.5rem;
  }

  .personFlex {
    flex-direction: row;
  }

}

@media (min-width: 2100px) {

  .mainText {
    font-size: 22px;
  }

  .navbarHeight {
    height: 3.5rem;
  }

  .personFlex {
    flex-direction: row;
  }
}