.information-dialog {
    inline-size: clamp(18.75rem, 7.7465rem + 46.9484vw, 50rem);
    /* background-color: transparent; */
}

.information-dialog-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;

    gap: 1rem;
}

.information-dialog__button {
    justify-self: center;
}

.information-heading {
    margin-block: 1rem;

    text-align: center;
}

.information-paragraph {
    text-align: justify;

    text-indent: 1rem;
}

.information-list-container {
    display: flex;
    flex-direction: column;

    margin-block-start: 1rem;
}

.information-list {
    display: flex;
    flex-direction: column;

    margin-block: 1rem;
    margin-left: 1rem;

    list-style: decimal;
}

.text-bold {
    font-weight: 700;
}

.text-italic {
    font-style: italic;
}