.my-table-container {
    margin-bottom: 1rem;
}

.my-table {
    width: 100%;

    margin-bottom: 1rem;
}

.my-table__cell {
    padding: 0.1rem;

    border: 2px solid #60a5fa;

    background-color: #fff;

    text-align: center;

    font-size: 10px;
}

@media(min-width: 480px) {
    .my-table__cell {
        font-size: 12px;
    }
}

@media(min-width: 640px) {
    .my-table__cell {
        font-size: 14px;
        padding: 0.4rem;
    }
}

@media(min-width: 1024px) {
    .my-table__cell {
        font-size: 16px;
    }
}

@media(min-width: 1366px) {
    .my-table__cell {
        font-size: 18px;
    }
}

@media(min-width: 1920px) {
    .my-table__cell {
        font-size: 20px;
    }
}

.my-table__buttons-container {
    display: flex;
    gap: 1rem;

    justify-content: center;
}