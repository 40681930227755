.my-textarea__label {
    display: flex;
    flex-direction: column;

    margin-bottom: 1rem;

    text-align: justify;
}

.my-textarea {
    width: 100%;

    padding: .5rem;

    border: 2px solid #60a5fa;
    /* border-radius: 1rem; */

    outline: none;
    resize: none;
    overflow: hidden;
}

.my-textarea:focus-visible {
    outline: none;
    resize: none;
    overflow: hidden;
}