.checkbox-container {
    margin-block: 1rem;
}

.checkbox-question__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    margin: 1rem 0;
}