.finish-page-container {
    width: 100%;

    height: 100vh;
}

.finish-page__text {
    width: fit-content;

    margin: auto;
    padding: 1rem;

    background-color: #fff;

    border: 2px solid #60a5fa;
    border-radius: 1rem;
}