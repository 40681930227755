/* Адаптация компонента Path (схемы) */
.orgtestPathsContainer {
  position: absolute;

  top: 0.5rem;
}

.orgtestLeftBorder {
  border-left: 2px solid #60a5fa;
}

.orgtestBackground {
  background-color: #60a5fa;
}

.taskOne {
  width: 83.333333%;
  height: 3rem;

  margin-left: auto;
  margin-right: auto;

  border-right: 2px solid #60a5fa;
  border-left: 2px solid #60a5fa;

  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;

  color: #fff;

  font-weight: bold;

  text-align: center;
}

.orgtestPopup {
  position: absolute;

  top: auto;
  right: 0rem;

  z-index: 20;

  display: flex;

  width: 100%;

  justify-content: center;


}

@media (min-width: 1640px) and (max-width: 1859px) {
  .pathComponent {
    width: 95%;
  }
}

@media (min-width: 1860px) and (max-width: 2000px) {
  .pathComponent {
    width: 80%;
  }
}

@media (min-width: 2000px) and (max-width: 2130px) {
  .pathComponent {
    width: 75%;
  }
}

@media (min-width: 2131px) and (max-width: 2249px){
  .pathComponent {
    width: 70%;
  }
}

@media (min-width: 2249px) and (max-width: 2600px) {
  .pathComponent {
    width: 66%;
  }
}

/* Адаптация ежедневника (отступ между элементами) */

@media (max-width: 2350px) {
  .secondLeftMargin {
    margin-left: 24px;
  }

  .thirdLeftMargin {
    margin-left: 0px;
  }

  .fourthLeftMargin {
    margin-left: 24px;
  }

  .fifthLeftMargin {
    margin-left: 0px;
  }
}

@media (min-width: 2351px) and (max-width: 2399px) {
  .secondLeftMargin {
    margin-left: 10px;
  }

  .thirdLeftMargin {
    margin-left: 10px;
  }

  .fourthLeftMargin {
    margin-left: 0px;
  }

  .fifthLeftMargin {
    margin-left: 10px;
  }
}

@media (min-width: 2400px) {
  .secondLeftMargin {
    margin-left: 24px;
  }

  .thirdLeftMargin {
    margin-left: 24px;
  }

  .fourthLeftMargin {
    margin-left: 0px;
  }

  .fifthLeftMargin {
    margin-left: 24px;
  }
}