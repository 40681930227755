/* App.js */
.accent {
  accent-color: #60a5fa;
}

.fontColor {
  color: #60a5fa;
}

.navLink:hover {
  color: #60a5fa;
}

.border {
  border: 2px solid #60a5fa;
}

@media (min-width: 376px) and (max-width: 575px) {
  .linksListWidth {
    width: 40%;
  }

  .linksFontSize {
    font-size: 12px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .linksListWidth {
    width: 30%;
  }

  .linksFontSize {
    font-size: 14px;
  }
 }

@media (min-width: 768px) and (max-width: 991px) {
  .linksListWidth {
    width: 25%;
  }

  .linksFontSize {
    font-size: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .linksListWidth {
    width: 25%;
  }

  .linksFontSize {
    font-size: 18px;
  }
}

@media (min-width: 1200px) and (max-width: 1919px) {
  .linksListWidth {
    width: 25%;
  }

  .linksFontSize {
    font-size: 20px;
  }
}

@media (min-width: 1920px) and (max-width: 2199px) {
  .linksListWidth {
    width: 25%;
  }

  .linksFontSize {
    font-size: 22px;
  }
}

@media (min-width: 2200px) {
  .linksListWidth {
    width: 25%;
  }

  .linksFontSize {
    font-size: 24px;
  }
}