.table {
    border: 2px solid #000;
}

.tr {
    border: 2px solid #000; 
}

.td {
    border: 2px solid #000;

    padding: 1rem;
}

.whiteBg {
    background-color: #fff;
}

.blueBg {
    background-color: #60a5fa;
}

.whiteText {
    color: #fff;
}
