.formButtonText {
  color: #60a5fa;
}

.formLabel {
  white-space: nowrap;
  font-weight: bold;
  margin-bottom: 0.3rem;

  width: 50%;
}

.formStaticFlex {
  flex-direction: row;
}

.formSelectAdaptiveFlex {
  flex-direction: column;
}

.formDialogContainer {
  position: absolute;

  width: 100%;

  bottom: 0rem;
  left: 0rem;
}

.formTextareaLabel {
  white-space: normal;
  font-weight: bold;

  margin-bottom: 0.3rem;
  margin-right: auto;
  margin-left: auto;

  width: 50%;
}

.formSelect {
  background-color: transparent;
  outline: none;

  font-weight: normal;
}

.formTextarea {
  font-weight: normal;

  outline: none;
  resize: none;
  overflow: hidden;

  padding: 0.5rem;

  border: 2px solid #60a5fa;
}

.formInputBorder {
  border-bottom: 2px solid #60a5fa;
}

.formAdaptiveInputWidth {
  width: 50%
}

.formInputLeftMargin {
  margin-left: 0.5rem;
}

.inputBottomMargin {
  margin-bottom: 0.5rem;
}

.formCaption {
  text-align: left;
  font-weight: bold;
}

.formTr {
  border: 2px solid #60a5fa;
}

.formTd {
  border-left: 2px solid #60a5fa;
  padding: 0.2rem;
}

.formInformationBorder {
  border-top: 2px solid #60a5fa;
}

.formLeftBorder {
  border-left: 2px solid #60a5fa;
}

.formPopup {
  position: absolute;

  top: -10rem;

  z-index: 20;
}

.formInput {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 2px solid #60a5fa;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
}

.formInput:focus {
  border-color: #3b82f6;
}

/* Стили для контейнера формы */
.holland-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Стили для заголовка формы */
.holland-form h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #60a5fa; /* Используем цвет из вашего form.css */
}

/* Стили для вопросов */
.holland-form .question {
  margin-bottom: 20px;
  padding: 10px;
  border: 2px solid #60a5fa; /* Используем цвет из вашего form.css */
  border-radius: 4px;
}

/* Стили для текста вопроса */
.holland-form .question p {
  font-weight: bold;
  margin-bottom: 10px;
}

/* Стили для вариантов ответа */
.holland-form label {
  display: block;
  margin: 5px 0;
  color: #333;
}

/* Стили для кнопки отправки */
.holland-form button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #60a5fa; /* Используем цвет из вашего form.css */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.holland-form button:hover {
  background-color: #3b82f6; /* Темнее для hover */
}


/* Стили для контейнера формы */
.holland-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Стили для заголовка формы */
.holland-form h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #60a5fa;
}

/* Стили для вопросов */
.holland-form .question {
  margin-bottom: 20px;
  padding: 10px;
  border: 2px solid #60a5fa;
  border-radius: 4px;
}

/* Стили для текста вопроса */
.holland-form .question p {
  font-weight: bold;
  margin-bottom: 10px;
}

/* Стили для вариантов ответа */
.holland-form label {
  display: block;
  margin: 5px 0;
  color: #333;
}

/* Стили для кнопки отправки */
.holland-form button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #60a5fa;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.holland-form button:hover {
  background-color: #3b82f6;
}
@media (min-width: 376px) and (max-width: 575px) {

  .formAdaptiveFlex {
    flex-direction: column;
  }

  .formStaticFlex {
    flex-direction: column;
  }

  .formLabel {
    white-space: normal;
  }

  .formAdaptiveInputWidth {
    width: 85%
  }

  .formAdaptivePopupWidth {
    width: 80%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {

  .formAdaptiveFlex {
    flex-direction: column;
  }

  .formStaticFlex {
    flex-direction: row;
  }

  .formLabel {
    white-space: normal;
  }

  .formPlaceholderText {
    font-size: 14px;
  }

  .formAdaptiveInputWidth {
    width: 80%
  }

  .formAdaptivePopupWidth {
    width: 80%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .formAdaptiveFlex {
    flex-direction: column;
  }

  .formLabel {
    white-space: normal;
  }

  .formAdaptiveInputWidth {
    width: 70%
  }

  .formAdaptivePopupWidth {
    width: 66%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  .formAdaptiveFlex {
    flex-direction: column;
  }

  .formAdaptiveInputWidth {
    width: 60%
  }

  .formAdaptivePopupWidth {
    width: 66%;
  }

}

@media (min-width: 1200px) and (max-width: 1499px) {

  .formAdaptiveFlex {
    flex-direction: column;
  }

  .formSelectAdaptiveFlex {
    flex-direction: column;
  }

  .formAdaptivePopupWidth {
    width: 66%;
  }
}

@media (min-width: 1500px) and (max-width: 1919px) {

  .mainText {
    font-size: 18px;
  }

  .navbarHeight {
    height: 3rem;
  }

  .formAdaptiveFlex {
    flex-direction: row;
  }

  .formSelectAdaptiveFlex {
    flex-direction: row;
  }

  .formAdaptivePopupWidth {
    width: 50%;
  }
}

@media (min-width: 1920px) and (max-width: 2099px) {

  .formAdaptiveFlex {
    flex-direction: row;
  }

  .formSelectAdaptiveFlex {
    flex-direction: row;
  }

  .formPlaceholderText {
    font-size: 20px;
  }

  .formAdaptivePopupWidth {
    width: 50%;
  }

}

@media (min-width: 2100px) {

  .formAdaptiveFlex {
    flex-direction: row;
  }

  .formSelectAdaptiveFlex {
    flex-direction: row;
  }

  .formPlaceholderText {
    font-size: 22px;
  }

  .formAdaptivePopupWidth {
    width: 50%;
  }

}