.my-button {
    width: fit-content;

    padding: 0.5rem;

    border: 2px solid #60a5fa;
    border-radius: 0.5rem;
    
    background-color: #fff;
  
    color: #60a5fa;

    cursor: pointer;
}

.my-button:hover {
    color: #fff;
  
    background-color: #60a5fa;
}
  
.my-button:active {  
    transform: scale(0.95);
}