.mainInfo {
  width: 83.333333%;

  padding: 0.5rem;

  margin-right: auto;
  margin-right: auto;

  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;

  text-align: center;

  font-weight: bold;

  color: #fff;
}

.mainInfoSecondSector {
  width: 83.333333%;

  display: flex;
  flex-direction: column;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.25rem;

  background-color: #fff;

  border: 2px solid #60a5fa;

  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;

  text-align: justify;
}

.orgTable {
  border: 2px solid #60a5fa;

  padding: 0;
}

.orgTh {
  border: 2px solid #60a5fa;

  background-color: #fff;

  padding: 0.2rem;
}

.orgTd {
  border: 2px solid #60a5fa;

  background-color: #fff;

  padding: 0.2rem;
}

.orgLabel {
  font-weight: bold;
}

.orgInput {
  border: 2px solid #60a5fa;
  border-radius: 0.5rem;

  outline: none;

  padding-left: 0.3rem;
}

.orgTextarea {
  border: 2px solid #60a5fa;
  border-radius: 0.5rem;

  font-weight: normal;

  outline: none;

  padding: 0.5rem;
}

.orgMainText {
  font-size: 20px;
}

.routeComponent {
  width: 80%;
}