.my-bottom-input__label {
    display: flex;
    flex-direction: column;

    margin-bottom: 1rem;
}

.my-bottom-input {
    width: 100%;

    border-bottom: 2px solid #60a5fa;

    background-color: transparent;

    text-align: justify;

    outline: none;
}

.my-bottom-input:-moz-focusring {
    outline: none;
}

@media(min-width: 1024px) {
    .my-bottom-input__label {
        flex-direction: row;

        white-space: nowrap;
    }

    .my-bottom-input {
        margin-left: 0.5rem;
    }

    .my-bottom-input_without-margin {
        margin-left: 0;
    }
}