.my-input {
    padding: 0.5rem;
    
    border: 2px solid #60a5fa;
    border-radius: 1rem;

    outline: none;
}

.my-input:-moz-focusring {
    outline: none;
}