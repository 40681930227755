.text-paragraph {
    margin-block: 1rem;
}

.text-paragraph__header {
    font-weight: 700;
}

.text-paragraph__text {
    text-align: justify;
}