.motivation-container {
  height: 100vh;
}

.motivation {
  margin-block: 2rem;
}

.motivation-question {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  align-items: center;

  padding: 1rem;

  border: 2px solid #60a5fa;
  border-radius: 1rem;
}

.motivation-question__text {
  width: 100%;

  font-weight: 700;
}

.motivation-question__list {
  display: flex;
  flex-direction: column;
  gap: .5rem;

  width: 100%;
}

.motivation-question__list-item {
  text-align: justify;
}

.motivation-inputs__list {
  display: flex;

  gap: 1rem;
}

.motivation-inputs__list-item__input {
  margin-left: .5rem;

  min-width: 2rem;
  max-width: 3rem;

  border: 2px solid #60a5fa;
  border-radius: .3rem;

  text-align: center;
}