.excelInputLabel {
    position: relative;
    display: inline-block;
}

.excelInput {
    position: absolute;

    display: block;

	width: 0;
	height: 0;

	z-index: -1;
	opacity: 0;
}

.excelInputSpan {
    position: relative;
	display: inline-block;

    width: 20rem;
    height: 5rem;

    margin: 0;

	cursor: pointer;
	outline: none;
	text-decoration: none;
	
	color: rgb(255 255 255);

	text-align: center;
    line-height: 5rem;
    
    border: none;
	border-radius: 1rem;
    
	background-color: #60a5fa;
	
	box-sizing: border-box;	

	transition: all 0.2s;
}

.excelInputSpan:hover {
    background-color: #4699ff;
	box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
 
/* Hover/active */
.excelInputSpan:hover {
	box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.excelInputSpan:active {
	transform: scale(0.95);
}


/* UsersCards */

.usersCards {
    display: grid;

    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;

    gap: 1rem;

    width: 80%;

    margin-top: 2rem;
    margin-right: auto;
    margin-left: auto;
}

.userCard {
    position: relative;

    min-height: 10rem;

    border-radius: 1rem;

    cursor: pointer;
}

.successCard {
    background-color: #4699ff;
}

.progressCard {
    background-color: #d1d9e2;
}

.userCardInfo, .userCardButtons {
    position: absolute;

    display: flex;

    text-align: center;
    justify-content: center;
    align-items: center;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    padding: 1rem;

    border-radius: 1rem;
}

.userCardInfo {
    color: #fff;
}

.userCardButtons {
    background-color: rgb(0, 0, 0, .5);

    z-index: -1;
}

.userCardInfo:hover + .userCardButtons,
.userCardButtons:hover {
    z-index: 10;
}

.userCardButton {
    padding: 0.5rem;

    border-radius: .5rem;

    background-color: #fff;
}

.userCardButton:active {
    transform: scale(0.95);
}

.userPopup {
    position: fixed;

    top: 0;
    left: 0;

    display: flex;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100vh;

    background-color: rgba(0, 0, 0, .5);

    z-index: 20;
}

.userFullCardContainer {
    width: 80%;
    height: 80%;

    display: flex;
    flex-direction: row;

    justify-content: center;
}

.userFullCard {
    width: 90%;

    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;

    padding: 2rem;

    border-radius: 1rem;
    background-color: #fff;

    overflow: scroll;
}

.userFullCardList {

}

.userFullCardItem {
    margin-bottom: 2rem;
}

.userFullCardButtonContainer {
    width: 2rem;
    height: 100%;

    align-items: center;
}

.userFullCardButton {
    width: 2rem;
    height: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100%;

    background-color: rgba(0, 0, 0, .5);

    color: #fff;
}

.userTableTh, .userTableTd {
    padding: 1rem;

    border: 2px solid #60a5fa;
}

.userTableTd {
    vertical-align: baseline;
}